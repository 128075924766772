.portal {
	display: block;
	margin-top: -1px;
	padding-top: 1px;

	:global(.card), :global(.card-header) {
		background: unset;
		color: unset;
	}

}

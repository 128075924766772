@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@import "~rev-less/themes/Nettrix";
@import '~rev-less/themes/Classic';
@import '~rev-less/themes/TwoTier';
@import '~rev-portal/navigation/mobileMenu/current-menu';

/* these are meant to be used generically across all themes */
main-header {
	.navbar {
		border: none;
		.RoundedShape(0);
		margin-bottom: 0;
	}

	nav.theme-accent-border {
		border-bottom-style: solid;
		border-bottom-width: 2px;
	}

	.brand-cell {
		position: relative;
		.skip-link {
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	branding-logo {
		img {
			@media all and (max-width: @viewPortVerySmallWidth) {
				height: 30px !important;
			}
		}
	}

	.skip-wrap {
		position: relative;

		.skip-link {
			width: 300px;
			z-index: 10010;
		}
	}

	.skip-link {
		color: var(--theme-header-txt);
		background: var(--theme-header);
		display: block;
		position: absolute;
		line-height: 48px;
		text-align: center;
		transition: transform 0.25s linear;
		transform: translate(0, -200px);

		&:focus {
			transform: translate(0, 0);
		}
	}

	.btn.main-nav-btn {
		@media all and (max-width: @viewPortVerySmallWidth) {
			height: 28px;
			min-width: 28px !important;
		}
	}

	.dropdown-menu.mobile-menu {
		@media all and (max-width: @viewPortVerySmallWidth) {
			top: 40px;
		}
	}
}

.admin {
	.card {

		.card-header {
			background: @adminPanel;

			h4 {
				font-size: 14px;
				margin: 0;
				line-height: 48px;
				color: @color1MedText;
				text-transform: uppercase;
				text-shadow: 0 1px 1px @white;
				font-weight: bold;
				vertical-align: middle;
			}
		}

		.card-subheader {
			border-top: 1px solid @borderColor;
		}

		.card-footer {
			background: @adminPanel;
		}
	}

	.card-body {

		background: @adminPanel;
		.RoundedShape(3px);

		.btn-admin:not(.active) {
			background: @whiteFade50;
		}

		fieldset .form-group:last-child {
			padding-bottom: 8px;
		}

		&.no-padding {
			padding: 0 !important;
			margin-top: 0;
		}

		&.highlight {
			form {
				padding: 15px 0;
			}

			.tab-pane {
				background: @white;

				.form-group {
					margin: 0;
				}
			}
		}
	}

	.form-group {
		padding-top: 8px;
		padding-bottom: 8px;
		margin: 0;

		&.no-padding-top {
			padding-top: 0;
		}

		.col-sm-6 {
			//for required icon positioning
			position: relative;
		}
	}

	.form-inline .form-group {
		padding-top: 5px;
		padding-bottom: 12px;
	}
}

.scroll-wrapper {
	position: relative;
	z-index: 0;
}

/* end library split view */

.form-button-row {
	margin: 15px -15px 15px -15px;

	&.panel-heading,
	&.panel-footer {
		margin: 15px 0;
		padding: 15px !important;

		h2, h3 {
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			line-height: 40px;
		}
	}

	&.panel-footer {
		margin: 0 0 15px 0;
	}

	&.panel-heading {
		margin-bottom: 0;
	}
}

.panel-sub-heading {
	padding: 15px;
	margin: 0 -15px;
	color: #596065;
	background: @blackFade05;
	font-size: 13px;
	.RoundedTopShape(4px);

	.table-cell & {
		margin: 0;
	}
}

.admin-content .panel {
	background: @white;
	border: none;
	.RoundedShape(4px);
	.BoxShadow(0 2px 2px @blackFade10);
}

.label-status-active {
	color: @whiteFade75;
	background: #00af43;
	font-size: 13px;
	font-weight: 500;
}

/* form-button-row override */
.setup .panel-footer.form-button-row {
	margin: 15px -30px !important;
	padding: 15px !important;
}

.new-panel {
	+ .new-panel {
		margin-top: 15px;
	}

	&.rounded {
		.RoundedShape(4px);
	}

	&-header {
		font-weight: 600;
		height: auto;

		h1,
		h2,
		h3,
		h4 {
			color: @color1MedText;
			line-height: 1.4;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			padding: @type16 0;
			text-transform: uppercase;
			vertical-align: middle;
		}

		p.help {
			text-transform: none;
			font-size: 11px;
			margin-top: 8px;
		}

		.accent & {
			background: rgba(89, 96, 101, 1);
			color: @whiteFade75;
		}

		.content & {
			text-transform: uppercase;
		}

		.admin-light & {
			background: @whiteFade50;
			color: rgb(89, 96, 101);
		}

		.rounded & {
			.RoundedTopShape(4px);

			.collapsed& {
				.RoundedShape(4px);
			}
		}
	}

	&-body {
		padding: 15px;

		.accent & {
			background: rgba(89, 96, 101, 0.3);
			padding: 15px;
		}

		.admin-light & {
			background: @white;
		}

		.rounded & {
			.RoundedShape(4px);

			&:last-child {
				.RoundedBottomShape(4px);
			}
		}
	}

	&-footer {
		margin-top: 0;
		padding: 15px;
		border-top: 1px solid @borderColor;

		.rounded & {
			.RoundedBottomShape(4px);
		}
	}

	&.admin-light {
		.RoundedShape(4px);
		background: @white;

		.table-tools {
			margin: -15px -15px 0 -15px;
			padding: 5px;
			background: rgba(89, 96, 101, 0.2);
			.BoxShadow(0 2px 0 fade(@black, 20%));
		}
	}

	&.new-panel-border .new-panel-header {
		border-bottom: 0;
		padding: 8px;
		text-transform: uppercase;
		background: rgba(89, 96, 101, 0.2);
	}

	.media-permissions.table thead > tr > th {
		background: rgba(89, 96, 101, 1);
		color: @whiteFade50;
	}
}

.dark-version .new-panel-header {
	background-color: rgba(89, 96, 101, 1);
	padding: 5px 15px 5px 15px;
	color: @whiteFade75;
}

.form-control-search.input-search-xs {
	padding: 2px 5px;
	font-size: 10px;
	border-width: 1px;
	.RoundedShape(3px);
	height: auto;
}

.teams-panel {

	h4 {
		font-size: 14px;
		font-weight: bold;
		padding-top: 8px;
	}

	.util-uppercase {
		font-size: 14px;
		font-weight: bold;
		padding-top: 8px;
	}

	.form-group {
		padding-top: 0;
	}

	.form-control {
		.BoxShadow(none);
	}

	.name-field {
		.success-field {
			top: auto;
			bottom: 2.5rem;
			right: -4px;
		}

		.error-field {
			position: static;
		}
	}

	@media (min-width: 768px) {
		.has-error .error-field {
			margin-left: 0;
		}
	}
}

@media (max-width: 900px) {
	.admin .panel {
		margin-bottom: 15px;
	}
}

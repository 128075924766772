@import "../global/mixins";
@import "../global/variables";

.admin-light {

	.admin-table {
		background: @white;
	}

	.combined-toolbar {
		.form-control,
		.input-group-highlighted,
		.input-group .btn {
			height: 30px;
		}

		.search-query-field {
			.form-control,
			.btn {
				height: 28px;
			}
		}
	}

	.inline-create-form {
		.combined-toolbar {
			.form-control,
			.input-group-highlighted {
				background-color: #f8fbfd;
				.BoxShadow(none);

				&:focus {
					background-color: #f8fbfd;
					color: @color1;
				}
			}

			.form-control.ng-valid {
				border: 1px solid @accentColor !important;
			}
		}

		&.child-form .combined-toolbar .form-control {
			background: @accentFade10;
		}
	}

}

.admin-light .table-list .file-list-header > div a,
.admin-light.admin .admin-table thead th a,
.admin-light.admin .admin-table thead th:hover a,
.admin-light .container-head > div a,
.admin-light .container-head:hover > div.admin a,
.admin-light .file-list .file-list-header .file-list-header-col a,
.file-list .table-cell.file-list-header-col,
.file-list .table-cell.file-list-header-col a {
	color: @color1LightText;
}

.admin-light .table-list .file-list-header > div a.active,
.admin-light.admin .admin-table thead th a.active,
.admin-light.admin .admin-table thead th:hover a.active,
.admin-light .container-head > div a.active,
.admin-light .container-head:hover > div.admin a.active,
.admin-light .file-list .file-list-header .file-list-header-col a.active,
.file-list .table-cell.file-list-header-col a.active {
	color: @color1;
}

.admin-table {
	th,
	td {
		height: 45px;
	}
}

.child-row {
	> div {
		background: @white;
		border-bottom: 1px solid #ccc;
		border-color: #ccc;
	}

	&:hover > div {
		background: rgb(243,251,255);
	}

	&.container-head:hover > div {
		background: @backgroundLighten03;
	}
}

.table-cell-library-name a,
.admin-table tbody tr th,
.admin-table tbody tr td {
  word-break: break-all;word-break: break-word;overflow-wrap: break-word;
}
.flex-table .scrollable {
  display: -webkit-flex;display: flex;
}
.table-cell-library-name a,
.admin-table tbody tr th,
.admin-table tbody tr td {
  word-break: break-all;word-break: break-word;overflow-wrap: break-word;
}
.flex-table .scrollable {
  display: -webkit-flex;display: flex;
}
.flex-table {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-items: center;
  align-items: center;
  justify-content: space-between;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  overflow: visible;
}
.flex-table .scrollable {
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  overflow-y: scroll;
  width: 100%;
}
.flex-table .flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  justify-content: space-between;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
}
.flex-table .flex-row > div:not(.flex-table) {
  -webkit-flex: 1 0 1px;
  flex: 1 0 1px;
  white-space: nowrap;
}
.flex-table .flex-row > div:not(.flex-table).small {
  -webkit-flex: 0 0 48px;
  flex: 0 0 48px;
}
.flex-table .flex-row > div:not(.flex-table).fill {
  -webkit-flex: 2 1 1px;
  flex: 2 1 1px;
}
.flex-table .flex-row > div:not(.flex-table).fill.double {
  -webkit-flex: 4;
  flex: 4;
}
.drag-handle {
  padding-left: 5px;
  padding-right: 2px;
  margin: 0;
  padding: 0 5px;
  display: inline-block;
}
.drag-handle .btn {
  color: rgba(0, 0, 0, 0.3);
  font-size: 10px;
  text-shadow: 0 1px #fff;
}
.drag-handle .btn:focus,
.drag-handle .btn:active {
  color: rgba(0, 0, 0, 0.8);
  background: none;
  box-shadow: none;
}
button .btn-collapse,
button .btn-expand {
  background: rgba(64, 89, 127, 0.25);
  font-size: 8px;
  border-radius: 0;
  padding: 0 1px 2px 1px;
  color: #fff;
  margin-left: 4px;
  padding: 1px 2px 3px 2px;
}
.btn-tree-control {
  font-size: 8px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 3px;
  height: 12px;
  width: 12px;
  color: rgba(0, 0, 0, 0.3);
  margin-right: 3px;
}
.btn-tree-control .glyphicons {
  position: relative;
  left: -2px;
  top: -3px;
}
.child-row {
  display: table;
  width: 100%;
  position: relative;
  margin-top: 1px;
}
.child-row a {
  text-decoration: none;
  display: block;
  color: #7d7d7d;
}
.child-row a:hover {
  color: #005580;
}
.child-row > div {
  display: table-cell;
  width: 1px;
  margin: 5px 0;
  background: #f2f2f2;
  border-color: #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  vertical-align: middle;
  font-weight: bold;
}
.child-row > div.first-item,
.child-row > div:first-child {
  vertical-align: middle;
  border-radius: 4px 0 0 4px;
  border-left: 1px solid #ccc;
  padding-left: 6px;
}
.child-row > div.first-item {
  text-align: center;
}
.child-row > div.last-item {
  border-radius: 0 4px 4px 0;
  border-width: 1px;
  border-right-style: solid;
  padding-right: 6px;
}
.child-row > div.expand-icon {
  vertical-align: middle;
  background: none !important;
  border: none;
  box-shadow: none;
  text-align: right;
}
.child-row > div.icon {
  vertical-align: middle;
  position: relative;
  width: 38px;
  text-align: center;
}
.child-row > div.spacer {
  background: none !important;
  border: 0;
  padding: 0 15px 0 0;
}
.child-row > div.table-cell-library-name {
  width: auto;
}
.child-row:hover > div {
  cursor: pointer;
}
.child-row.add-child > div,
.child-row.add-child:hover > div {
  background-color: rgba(0, 161, 240, 0.1);
  border-color: rgba(0, 161, 240, 0.25);
}
.child-row.locked a {
  color: #aaa;
}
.child-row.locked:hover > div {
  background: #f2f2f2;
  cursor: default;
}
.child-row .table-cell > a {
  display: block;
  white-space: nowrap;
  padding: 0 15px;
}
.child-row .table-cell.table-cell-library-name > a,
.child-row .table-cell.table-cell-library-name > button {
  display: inline-block;
  padding-left: 0;
}
.child-row .admin-movable-item {
  display: table-cell;
}
.child-row:hover > div,
.child-row.vb-droppable-hover > div {
  background: #fff;
}
.vb-draggable-helper {
  width: 500px;
  z-index: 100;
  display: block;
  border-radius: 4px;
  background: rgba(0, 161, 240, 0.5);
  padding: 6px 6px 3px 32px;
  margin-left: 32px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.vb-draggable-helper .child-row > div {
  background: #fff;
}
.vb-draggable-helper .btn-add {
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
}
.vb-draggable-helper.vb-draggable-hover .show-on-drag-target {
  display: block;
  position: absolute;
  left: 9px;
  top: 14px;
}
.vb-draggable-helper .child-row:focus,
.vb-draggable-helper .child-row:focus > div,
.vb-draggable-helper .child-row:focus a,
.vb-draggable-helper .child-row:focus .btn {
  cursor: default;
  cursor: hand;
  cursor: move;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.vb-draggable-helper .spacer,
.show-on-drag-target {
  display: none;
}
.vb-draggable-helper .hide-if-draggable-helper,
.vb-dragging .hide-while-dragging {
  display: none !important;
}
.child-row > div.drag-handle:hover,
.child-row > div.drag-handle:hover .btn,
.vb-draggable-helper .child-row:hover,
.vb-draggable-helper .child-row:hover > div,
.vb-draggable-helper .child-row:hover a,
.vb-draggable-helper .child-row:hover .btn {
  cursor: default;
  cursor: hand;
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}
/* .draggable-to-table fakes turning a draggable item list back into an admin table  */
.draggable-to-table.draggable-table {
  padding-bottom: 0;
}
.draggable-to-table .draggable-to-table .child-row > div.first-item,
.draggable-to-table .draggable-to-table .child-row > div.last-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
.draggable-to-table .child-container-padding {
  padding: 0;
}
.item-count {
  text-align: center;
}
.item-count a {
  padding-right: 30px;
  width: 140px;
}
.child-row-child .form-group {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}
.child-row-child h4 {
  color: #fff;
  margin-top: 0;
}
.sub-dir .child-row-child {
  margin-left: 30px;
}
.container-head > div {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.container-head > div a {
  color: #898989 !important;
}
.container-head > div:first-child a {
  margin-left: 5px;
}
.container-head > div.table-cell-library-name {
  padding-left: 5px;
}
.container-head > div.last-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  padding-right: 50px;
}
.container-head > div,
.container-head:hover > div {
  background: #596065;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 10px;
  padding-bottom: 10px;
}
.insertion-marker {
  display: none;
  position: absolute;
  right: 0;
  left: 25px;
  top: 24px;
  z-index: 1;
}
.insertion-marker .line {
  border-bottom: 4px solid #00a1f0;
}
.insertion-marker-icon {
  position: relative;
  top: 9px;
  background: #00a1f0;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  color: #fff;
}
.vb-droppable-hover .insertion-marker {
  display: block;
}
/* child-form-2 TEST */
.child-form-2 {
  margin: 10px 30px 6px 30px;
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 10px 0;
  vertical-align: middle;
  font-weight: bold;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.child-form-2 .form-group {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}
.child-form-2 h4 {
  color: #fff;
  margin-top: 0;
}
.inline-create-form.child-form,
.inline-create-form.child-form-2 {
  background: none;
  padding: 15px 15px 15px 30px;
  box-shadow: none;
  margin: 0;
  border-radius: 3px;
}
.inline-create-form.child-form {
  margin: 0 16px 1px 48px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.75);
}
/* OLD PAGE FIXES */
.tree-container {
  display: table;
  width: 100%;
  margin-bottom: 4px;
  position: relative;
}
.tree-container a {
  text-decoration: none;
  display: block;
}
.tree-container > div {
  display: table-cell;
  width: 20%;
  width: 1px;
  margin: 5px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-color: #ccc;
  padding: 6px;
  vertical-align: middle;
  font-weight: normal;
}
.tree-container:hover > div {
  background: #fff;
  cursor: pointer;
  cursor: hand;
}
.tree-container .add-child > div,
.tree-container .add-child:hover > div {
  background-color: rgba(0, 161, 240, 0.1);
  border-color: rgba(0, 161, 240, 0.25);
}
.root-insertion-marker {
  height: 10px;
  margin-top: -10px;
}
.root-insertion-marker .insertion-marker {
  left: 15px;
}
.child-container-padding {
  padding: 0 8px;
}
.child-container .vb-droppable,
.tree-item .vb-droppable {
  position: relative;
}
.child-sub-container {
  margin-left: 15px;
}
div.expand-icon button {
  margin: 0 5px;
}
.sub-dir .child-row > div.expand-icon button {
  margin-left: 30px;
}
.child-form {
  margin: 0 10px 5px 58px;
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 10px 0;
  vertical-align: middle;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.collection-tree .child-container {
  padding: 0 15px;
}
.sample-cursor {
  position: absolute;
  top: 15px;
  left: 0;
  font-size: 18px;
}
.align-right-float-left {
  text-align: right;
  float: left;
  width: 50px;
}
.draggable-table {
  position: relative;
  background: rgba(89, 96, 101, 0.3);
  padding: 0;
  padding-bottom: 15px;
}
.admin select.form-control.ng-invalid.ng-dirty {
  border: 1px solid rgba(180, 45, 36, 0.5);
  color: #b42d24;
}
.customize-device.error {
  border: 1px solid #b42d24;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
}
.customize-device-error {
  background: none;
  border: 1px solid #b42d24;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}
.data-table td,
.data-table th {
  vertical-align: middle !important;
}
.data-table tbody a {
  color: #565656;
}
.data-table.table-hover tbody > tr:hover a {
  color: #108dda;
}
.control-label {
  color: #898989;
  font-weight: normal;
  margin-bottom: 4px;
}
h4 .control-label {
  color: #acaeb0;
}
.control-label,
.form-horizontal .control-label {
  text-align: left;
  margin-bottom: 4px;
}
.control-label.has-error {
  color: #b42d24;
}
.required-message-small {
  font-size: 0.875rem;
  font-style: italic;
  padding-left: 15px;
}
select.form-control {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.panel-heading .float-right input[type=checkbox] {
  margin: 4px 0 0;
}
h4 .form-group.float-right {
  position: relative;
  top: -10px;
}
.checkbox-single {
  margin-right: 10px;
  font-weight: 400;
  line-height: 29px;
}
.btn-link:active .btn-link:focus {
  border: none;
}
.admin .report-view .form-group {
  padding: 8px 0;
}
.admin .form-group.float-right,
.admin .form-group.float-left {
  margin: 0;
}
.admin .form-group.pad-left {
  padding-left: 15px !important;
  padding-bottom: 10px !important;
}
.admin .editableform .form-group {
  padding: 0;
}
.admin .fieldset {
  margin: 15px 0;
  border: 1px solid #cbcbcb;
}
.admin .fieldset .form-group {
  margin: 0;
}
.admin .new-panel .required .required-field:before,
.admin .new-panel .has-success .success-field:before,
.admin .new-panel .has-error .error-field:before {
  left: inherit;
  right: 0;
  top: 30px;
}
.admin .new-panel .help-block {
  height: 22px;
}
.admin .tab-content {
  border-top: 0;
  border-radius: 0 0 4px 4px;
}
.admin .admin select.form-control {
  background: linear-gradient(to bottom, #fff, #f8fbfd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #f8f8f8;
  border: none;
}
.admin-content .nav-tabs a {
  padding: 8px 12px;
}
textarea.form-control {
  height: auto;
  resize: vertical;
}
textarea.form-control,
.has-success textarea.form-control {
  height: auto;
}
.row-margin {
  margin: 20px -10px;
}
.progress-bgColor {
  background-color: #DDD;
}
[uib-typeahead-popup].dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  right: 16px;
}
[uib-typeahead-popup].dropdown-menu .active > a {
  background-color: #d8f0fc;
  color: #09507b;
  border-color: #108dda;
}
[uib-typeahead-popup].dropdown-menu a {
  border-left: 4px solid transparent;
  line-height: 17px;
}
@media (max-width: 768px) {
  .table-cell-width-small {
    width: 30%;
    text-align: center !important;
  }
}
@media (max-width: 480px) {
  .table-status {
    text-align: center;
  }
}
.admin-light .admin-table {
  background: #fff;
}
.admin-light .combined-toolbar .form-control,
.admin-light .combined-toolbar .input-group-highlighted,
.admin-light .combined-toolbar .input-group .btn {
  height: 30px;
}
.admin-light .combined-toolbar .search-query-field .form-control,
.admin-light .combined-toolbar .search-query-field .btn {
  height: 28px;
}
.admin-light .inline-create-form .combined-toolbar .form-control,
.admin-light .inline-create-form .combined-toolbar .input-group-highlighted {
  background-color: #f8fbfd;
  box-shadow: none;
}
.admin-light .inline-create-form .combined-toolbar .form-control:focus,
.admin-light .inline-create-form .combined-toolbar .input-group-highlighted:focus {
  background-color: #f8fbfd;
  color: #303030;
}
.admin-light .inline-create-form .combined-toolbar .form-control.ng-valid {
  border: 1px solid #108dda !important;
}
.admin-light .inline-create-form.child-form .combined-toolbar .form-control {
  background: rgba(16, 141, 218, 0.1);
}
.admin-light .table-list .file-list-header > div a,
.admin-light.admin .admin-table thead th a,
.admin-light.admin .admin-table thead th:hover a,
.admin-light .container-head > div a,
.admin-light .container-head:hover > div.admin a,
.admin-light .file-list .file-list-header .file-list-header-col a,
.file-list .table-cell.file-list-header-col,
.file-list .table-cell.file-list-header-col a {
  color: #898989;
}
.admin-light .table-list .file-list-header > div a.active,
.admin-light.admin .admin-table thead th a.active,
.admin-light.admin .admin-table thead th:hover a.active,
.admin-light .container-head > div a.active,
.admin-light .container-head:hover > div.admin a.active,
.admin-light .file-list .file-list-header .file-list-header-col a.active,
.file-list .table-cell.file-list-header-col a.active {
  color: #303030;
}
.admin-table th,
.admin-table td {
  height: 45px;
}
.child-row > div {
  background: #fff;
  border-bottom: 1px solid #ccc;
  border-color: #ccc;
}
.child-row:hover > div {
  background: #f3fbff;
}
.child-row.container-head:hover > div {
  background: #f3f3f3;
}
.admin .card .card-header {
  background: #f3f3f3;
}
.admin .card .card-header h4 {
  font-size: 14px;
  margin: 0;
  line-height: 48px;
  color: #565656;
  text-transform: uppercase;
  text-shadow: 0 1px 1px #fff;
  font-weight: bold;
  vertical-align: middle;
}
.admin .card .card-subheader {
  border-top: 1px solid #cbcbcb;
}
.admin .card .card-footer {
  background: #f3f3f3;
}
.admin .card-body {
  background: #f3f3f3;
  border-radius: 3px;
}
.admin .card-body .btn-admin:not(.active) {
  background: rgba(255, 255, 255, 0.5);
}
.admin .card-body fieldset .form-group:last-child {
  padding-bottom: 8px;
}
.admin .card-body.no-padding {
  padding: 0 !important;
  margin-top: 0;
}
.admin .card-body.highlight form {
  padding: 15px 0;
}
.admin .card-body.highlight .tab-pane {
  background: #fff;
}
.admin .card-body.highlight .tab-pane .form-group {
  margin: 0;
}
.admin .form-group {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
}
.admin .form-group.no-padding-top {
  padding-top: 0;
}
.admin .form-group .col-sm-6 {
  position: relative;
}
.admin .form-inline .form-group {
  padding-top: 5px;
  padding-bottom: 12px;
}
.scroll-wrapper {
  position: relative;
  z-index: 0;
}
/* end library split view */
.form-button-row {
  margin: 15px -15px 15px -15px;
}
.form-button-row.panel-heading,
.form-button-row.panel-footer {
  margin: 15px 0;
  padding: 15px !important;
}
.form-button-row.panel-heading h2,
.form-button-row.panel-footer h2,
.form-button-row.panel-heading h3,
.form-button-row.panel-footer h3 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: 40px;
}
.form-button-row.panel-footer {
  margin: 0 0 15px 0;
}
.form-button-row.panel-heading {
  margin-bottom: 0;
}
.panel-sub-heading {
  padding: 15px;
  margin: 0 -15px;
  color: #596065;
  background: rgba(0, 0, 0, 0.05);
  font-size: 13px;
  border-radius: 4px 4px 0 0;
}
.table-cell .panel-sub-heading {
  margin: 0;
}
.admin-content .panel {
  background: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.label-status-active {
  color: rgba(255, 255, 255, 0.75);
  background: #00af43;
  font-size: 13px;
  font-weight: 500;
}
/* form-button-row override */
.setup .panel-footer.form-button-row {
  margin: 15px -30px !important;
  padding: 15px !important;
}
.new-panel + .new-panel {
  margin-top: 15px;
}
.new-panel.rounded {
  border-radius: 4px;
}
.new-panel-header {
  font-weight: 600;
  height: auto;
}
.new-panel-header h1,
.new-panel-header h2,
.new-panel-header h3,
.new-panel-header h4 {
  color: #565656;
  line-height: 1.4;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 1rem 0;
  text-transform: uppercase;
  vertical-align: middle;
}
.new-panel-header p.help {
  text-transform: none;
  font-size: 11px;
  margin-top: 8px;
}
.accent .new-panel-header {
  background: #596065;
  color: rgba(255, 255, 255, 0.75);
}
.content .new-panel-header {
  text-transform: uppercase;
}
.admin-light .new-panel-header {
  background: rgba(255, 255, 255, 0.5);
  color: #596065;
}
.rounded .new-panel-header {
  border-radius: 4px 4px 0 0;
}
.collapsed.rounded .new-panel-header {
  border-radius: 4px;
}
.new-panel-body {
  padding: 15px;
}
.accent .new-panel-body {
  background: rgba(89, 96, 101, 0.3);
  padding: 15px;
}
.admin-light .new-panel-body {
  background: #fff;
}
.rounded .new-panel-body {
  border-radius: 4px;
}
.rounded .new-panel-body:last-child {
  border-radius: 0 0 4px 4px;
}
.new-panel-footer {
  margin-top: 0;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.rounded .new-panel-footer {
  border-radius: 0 0 4px 4px;
}
.new-panel.admin-light {
  border-radius: 4px;
  background: #fff;
}
.new-panel.admin-light .table-tools {
  margin: -15px -15px 0 -15px;
  padding: 5px;
  background: rgba(89, 96, 101, 0.2);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
}
.new-panel.new-panel-border .new-panel-header {
  border-bottom: 0;
  padding: 8px;
  text-transform: uppercase;
  background: rgba(89, 96, 101, 0.2);
}
.new-panel .media-permissions.table thead > tr > th {
  background: #596065;
  color: rgba(255, 255, 255, 0.5);
}
.dark-version .new-panel-header {
  background-color: #596065;
  padding: 5px 15px 5px 15px;
  color: rgba(255, 255, 255, 0.75);
}
.form-control-search.input-search-xs {
  padding: 2px 5px;
  font-size: 10px;
  border-width: 1px;
  border-radius: 3px;
  height: auto;
}
.teams-panel h4 {
  font-size: 14px;
  font-weight: bold;
  padding-top: 8px;
}
.teams-panel .util-uppercase {
  font-size: 14px;
  font-weight: bold;
  padding-top: 8px;
}
.teams-panel .form-group {
  padding-top: 0;
}
.teams-panel .form-control {
  box-shadow: none;
}
.teams-panel .name-field .success-field {
  top: auto;
  bottom: 2.5rem;
  right: -4px;
}
.teams-panel .name-field .error-field {
  position: static;
}
@media (min-width: 768px) {
  .teams-panel .has-error .error-field {
    margin-left: 0;
  }
}
@media (max-width: 900px) {
  .admin .panel {
    margin-bottom: 15px;
  }
}
.admin .table {
  color: #555;
  background: #f1f2f3;
}
.admin .table tbody > tr.new > td {
  background: rgba(0, 0, 0, 0.05);
}
.admin thead th > a {
  color: rgba(255, 255, 255, 0.7);
}
.admin .sort-col > a.active {
  font-weight: bold;
}
.admin .sort-col > a.active > .caret {
  display: inline-block;
}
.admin .sort-col > a > .caret {
  display: none;
}
.admin .tree-container:hover > div {
  background-color: rgba(0, 161, 240, 0.1);
}
.admin .tree-container.selected:hover > div {
  background-color: #fff;
}
.tree-container > div {
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-color: rgba(0, 0, 0, 0.05);
}
.tree-container > div:first-child {
  border-color: rgba(0, 0, 0, 0.05);
}
.table-cell-library-name a {
  color: #303030;
  font-weight: bold;
}
.table-cell-library-name span {
  font-weight: normal;
}
.item-icon-label {
  margin-right: 5px;
  font-size: 14px;
}
.api-key-cell-1,
.api-key-cell-2 {
  width: 20%;
}
.api-key-cell-3 {
  text-align: right !important;
  white-space: nowrap;
}
.api-key-cell-4 {
  width: 30%;
}
.admin .tabbed-panel .nav-tabs {
  background: #d0d2d3;
}
.admin .tabbed-panel .nav-tabs > li > a:hover {
  color: #108dda;
  text-decoration: none;
  background: #f3f3f3;
}
.admin .tabbed-panel .nav-tabs > li.active > a:hover {
  color: #565656;
  text-decoration: none;
  background: #f3f3f3;
}
.admin .fancy-tabs .nav-tabs {
  background: none;
}
.admin .fancy-tabs .nav-tabs > li a {
  padding: 15px 5px;
  text-shadow: none;
  height: auto;
  bottom: 0;
  text-align: center;
  margin: 0;
  font-size: 14px;
}
.admin .fancy-tabs .nav-tabs > li.active a {
  background: #bbbdbf;
  border-bottom: 1px solid #bbbdbf !important;
  text-shadow: none;
  color: #303030;
}
.fancy-tabs a .icon-and-badge {
  font-size: 18px;
  line-height: 18px;
}
.fancy-tabs a .text-label {
  display: block;
  margin-top: 8px;
  font-weight: bold;
}
.fancy-tabs a .badge {
  background: #108dda;
  font-size: 13px;
  top: -4px;
  position: relative;
}
.fancy-tabs .nav-tabs.nav-justified > a,
.fancy-tabs .nav-tabs.nav-justified > .active > a,
.fancy-tabs .nav-tabs.nav-justified > .active > a:hover,
.fancy-tabs .nav-tabs.nav-justified > .active > a:focus {
  border-color: transparent;
}
.fancy-tabs .nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
}
@media (max-width: 600px) {
  .admin .fancy-tabs .nav-tabs {
    margin-top: 0;
    padding: 0;
  }
  .admin .panel-heading-with-tabs-fix .nav-tabs li {
    width: 50%;
    display: inline-block;
    margin: 0;
  }
  .admin .panel-heading-with-tabs-fix .nav-tabs li a {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0;
  }
}
.video-stream {
  padding: 8px;
  background: #fff;
  margin-bottom: 8px;
  border-radius: 3px;
}
.video-stream.new {
  background: rgba(16, 141, 218, 0.1);
}
.video-stream label {
  font-weight: normal;
  color: #303030;
}
.video-stream .video-stream-header {
  margin: -8px 0 0 0;
  padding: 0;
}
.video-stream .video-stream-header .combined-toolbar {
  margin: 0;
}
.video-stream .video-stream-header .combined-toolbar h4 {
  margin: 0;
  line-height: 40px;
  font-size: 1rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  font-weight: bold;
  color: #565656;
  line-height: 1.3;
  word-break: break-all;
}
.has-error.video-stream .video-stream-header .combined-toolbar h4 {
  color: #b42d24;
}
.video-stream .video-stream-header .combined-toolbar input {
  margin-top: 8px;
}
.video-stream .video-stream-header .combined-toolbar.not-new-stream-group {
  border-bottom: 1px solid rgba(48, 48, 48, 0.1);
}
.video-stream .video-stream-body {
  margin: 0 -8px;
  padding-left: 8px;
}
.video-stream ul.streams {
  margin: 8px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.video-stream ul.streams li {
  margin-top: 14px;
}
.video-stream ul.streams li:first-child {
  margin-top: 0;
}
.video-stream li {
  color: #7d7d7d;
}
.video-stream li strong {
  width: 70px;
  margin-right: 10px;
}
.video-stream vb-input[inline] {
  display: block;
  margin: 0;
}
.video-stream .form-group {
  padding: 4px 0 !important;
}
.video-stream .required-field,
.video-stream .success-field {
  position: absolute;
  top: -6px !important;
}
@media (max-width: 767px) {
  .video-stream .required-field,
  .video-stream .success-field {
    right: -18px !important;
  }
}
.video-stream vb-input .has-error .error-field {
  position: static;
  text-align: right;
  margin: 7px 2px 0 0;
}
.video-stream vb-input .has-error .error-field .control-label {
  padding: 0;
}
.video-stream .vb-multicast-ip .has-error .error-field,
.video-stream .vb-multicast-port .has-error .error-field,
.video-stream .vb-multicast-packet-size .has-error .error-field {
  text-align: left;
  float: right;
  clear: right;
  max-width: 100%;
}
.video-stream .vb-multicast-ip .has-error .error-field .control-label,
.video-stream .vb-multicast-port .has-error .error-field .control-label,
.video-stream .vb-multicast-packet-size .has-error .error-field .control-label {
  white-space: normal;
}
.video-stream .vb-multicast-ip .bytes,
.video-stream .vb-multicast-port .bytes,
.video-stream .vb-multicast-packet-size .bytes {
  position: absolute;
  top: 3px;
  right: -20px;
}
.video-stream.processing {
  color: #303030;
  background: #f1f2f3;
}
.video-stream.processing .video-stream-header {
  margin-bottom: -8px;
  border-bottom: 0;
}
.video-stream:not(.has-error) .form-control:focus {
  border-color: rgba(16, 141, 218, 0.5);
}
.video-stream .btn-admin.btn-white:not(:hover) {
  background: none;
}
.video-stream.has-error,
.video-stream-input.has-error {
  color: #b42d24;
  background: rgba(180, 45, 36, 0.1);
}
.video-stream-delete-warning {
  background: #fcf8e3;
}
.video-stream .vbrick-multicast {
  margin-top: 15px;
  background: rgba(16, 141, 218, 0.1);
  padding: 5px;
}
.video-stream .vbrick-multicast .bytes {
  position: absolute;
  top: 10px;
  right: -20px;
}
.video-stream .vbrick-multicast .bytes-help-text {
  font-size: 0.75rem;
}
.video-stream .vbrick-multicast input[type=checkbox] {
  margin: 1px 5px 0 0;
}
.video-stream .multicast-group-error .error-field {
  position: relative;
  top: 0;
}
@media (min-width: 320px) {
  .video-stream .multicast-group-error .error-field {
    right: -15px;
  }
}
@media (min-width: 768px) {
  .video-stream .multicast-group-error .error-field {
    right: -32px;
  }
}
.required-indicator {
  display: none;
  color: #b42d24;
  font-size: 18px;
  font-weight: 400;
}

@import (reference) '~rev-less/global/variables';

.wrapper {
	height: 100%;
}

.header {
	font-size: @type21;
	padding: 15px !important;
	text-transform: uppercase;
}

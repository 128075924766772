@import "~rev-less/global/variables";

help-menu, help-menu-mobile {
	display: block;

	.dropdown-menu {
		margin: 16px !important;
		--bs-dropdown-item-padding-x: 8px !important;
		--bs-dropdown-item-padding-y: 8px !important;

		.list-group {
			padding: 8px;
		}
	}

	.help-tray .vb-icon-help {
		margin-top: 4px;
	}

	.help-menu-button .active {
		background-color: var(--theme-accent) !important;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}

	.label-container {
		flex-grow: 1;
	}

	a {
		margin-top: 2px;
	}

	a:hover {
		text-decoration: none;
	}

	.help-nav {
		margin: 0;
		max-height: 300px;
		overflow-y: auto;

		&-link {
			border-radius: 8px;

			&:hover {
				background-color: var(--theme-header-overlay) !important;
			}
		}

		.divider {
			margin: 0;
			height: 1px;
			overflow: hidden;
			background-color: var(--theme-header-txt);
		}
	}
	a.main-nav-btn {
		display: inline-block;
		padding: 13px 12px;
		line-height: 1.4;
		position: relative;
	}
}


media-menu-dropdown {
	.dropdown-toggle, [dropdowntoggle] {
		padding-top: 2px;
	}

	button.dropdown-toggle {
		background: none;
		border: none;
	}

	media-menu {
		.media-videos {
			margin: -9px;
			border-radius: 8px 0 0 8px;
		}

		.media-teams {
			margin: -9px;
			border-radius: 0 8px 8px 0;
		}
	}
}

.theme-TwoTier,
.theme-Classic {
	mobile-menu {
		.dropdown-menu.mobile-menu {
			max-height: 90%;
			left: 0;
			margin: 0;
			overflow-y: auto;
			padding-bottom: 50px;
			position: fixed;
			right: 0;

			&-list {
				margin-top: 0;
			}
		}

		.main-nav-btn {
			height: 36px;
			width: 36px;
			left: -1px;

			.glyphicons {
				font-size: 1.5em;
				vertical-align: middle;
				top: 0;
			}
		}

		.btn-mobile-open {
			left: 0;
			margin-left: 0;
		}

		.open {
			.dropdown-toggle {
				&:after, &:before {
					display: none;
				}
			}
		}

		.mobile-menu-list-item {

			a, button {
				font-size: @type16;
				margin-left: 0 !important;
				text-align: left;
				text-decoration: none;

				&:hover, &:focus {
					text-decoration: none !important;
				}
			}

			&.active {
				&:after {
					width: 0;
				}
			}

			&.theme-accent-btn:hover {
				background-color: @accentColor !important;
			}
		}

		search-header .form-control {
			&:not(:hover):not(:focus) {
				color: @whiteFade50 !important;
			}

			.Placeholder({
				color: @whiteFade50 !important;
			})
		}

		.search-cell vb-ui-typeahead-angular input {
			position: relative;
			background-color: var(--theme-header) !important;
			border: none;
			border-radius: 0;
			border-bottom: 1px solid var(--theme-header-txt) !important;
			border-right-style: none;
			padding-bottom: 6px;
			padding-left: 0;
			box-shadow: none !important;

			&:focus {
				border-width: 1px;
				box-shadow: none !important;
			}
		}
	}
}

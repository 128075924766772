notifications-menu-content {
	display: block;

	.scrollable-area {
		height: 300px;
	}

	.menu-footer {
		font-size: 13px;
		position: relative;

		button:hover, a:hover {
			color: initial;
			text-decoration: underline;
		}
	}

	.no-notifications-msg {
		margin: 15px;
		height: ~'calc(100% - 30px)';

		.no-notifications-link {
			text-decoration: underline;
		}
	}
}

@import "~rev-less/global/variables";
@import "~rev-less/global/mixins";

vb-notification {
	.media-body-wrapper {
		padding: 8px;
		.BoxShadow(0 1px 2px @blackFade25);
		background-color: @white;

		&.notification-alert {
			background: @warningColor;
			border-top: 1px solid @backgroundLighten03;
			border-bottom: 1px solid @backgroundLighten03;

			p, h4, .glyphicons, .vb-icon {
				color: @white;
			}
		}

		.is-read {
			color: @color1LightText;
			background: @whiteFade75;
			.BoxShadow(none);
			border: 1px solid @borderColorLight;
			border-top: 0;

			h4 {
				color: @color1LightText;
			}

			.notification-tab {
				.glyphicons, .vb-icon {
					color: @color1LightText;
				}
			}
		}

		.notification-tab {
			width: 32px;
			font-size: 16px;
			position: absolute;
			top: 0;
			bottom: 0;
			text-align: center;

			.glyphicons, .vb-icon {
				margin-top: 8px;
				margin-left: 2px;
			}
		}

		.notification-details-list {
			color: @white;
			margin-bottom: 5px;
		}
	}
}

@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.notifications-count {
	border: 1px solid var(--theme-header-txt);
	border-radius: 27px;
	background-color: @accentColor;
	position: absolute;
	top: 0;
	left: 16px;
	font-size: 12px;
	line-height: 14px !important;
	color: @white !important;
	border-color: @white;
	z-index: 1;

	@media(max-width: 992px) {
		margin-left: 0 !important;
	}
}

.high .notifications-count {
	background-color: @warningColor;
}

notifications-menu-button {
	display: block;
	margin-right: 4px !important;

	.notification-menu {
		width: 400px;
		left: auto;
		right: 0;

		@media(max-width: @viewPortSmallWidth){
			width: auto;
			position: fixed;
			left: 0 !important;
			right: 0 !important;
		}
	}

	&:not(.count-bubble) .high {
		background: @warningColor !important;
	}

	&.count-bubble {
		.notifications {
			background-color: transparent;

			&:not(.open) {
				&:hover,
				&.high {
					background-color: transparent !important;
				}
			}

			&.open {
				.notifications-count {
					display: none;
				}
			}


			&.high .notifications-count {
				color: @white;
				background: @warningColor;
			}
		}
	}
}

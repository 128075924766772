@import (reference) "~rev-less/global/mixins";

.drag-handle {
	padding-left:5px;
	padding-right:2px;
	margin: 0;
	padding: 0 5px;
	display: inline-block;

	.btn {
		color: fade(@black, 30%);
		font-size: 10px;
		text-shadow: 0 1px @white;

		&:focus,
		&:active {
			color: fade(@black, 80%);
			background: none;
			.BoxShadow(none);
		}
	}
}

button .btn-collapse,
button .btn-expand {
	background: rgba(64,89,127,0.25);
	font-size: 8px;
	.RoundedShape(0);
	padding: 0 1px 2px 1px;
	color: @white;
	margin-left: 4px;
	padding: 1px 2px 3px 2px;
}

.btn-tree-control {
	font-size: 8px;
	background: @blackFade10;
	border: 1px solid @blackFade10;
	.RoundedShape(0);
	padding: 3px;
	height: 12px;
	width: 12px;
	color: fade(@black, 30%);
	margin-right: 3px;

	.glyphicons {
		position: relative;
		left: -2px;
		top: -3px;
	}
}

.child-row {
	display: table;
	width: 100%;
	position: relative;
	margin-top: 1px;

	a {
		text-decoration: none;
		display: block;
		color: #7d7d7d;

		&:hover {
			color: rgb(0,85,128);
		}
	}

	> div  {
		display: table-cell;
		width: 1px;
		margin: 5px 0;
		background: #f2f2f2;
		border-color: #ccc;
		border-bottom: 1px solid #ccc;
		padding: 5px;
		vertical-align: middle;
		font-weight: bold;

		&.first-item,
		&:first-child
		{
			vertical-align: middle;
			.RoundedLeftShape(4px);
			border-left: 1px solid #ccc;
			padding-left: 6px;
		}

		&.first-item {
			text-align: center;
		}

		&.last-item {
			.RoundedRightShape(4px);
			border-width: 1px;
			border-right-style: solid;
			padding-right: 6px;
		}

		&.expand-icon  {
			vertical-align: middle;
			background: none !important;
			border: none;
			.BoxShadow(none);
			text-align: right;
		}

		&.icon  {
			vertical-align: middle;
			text-align: center;
			position: relative;
			width: 38px;
			text-align: center;
		}

		&.spacer  {
			background: none !important;
			border: 0;
			padding: 0 15px 0 0;
		}

		&.table-cell-library-name {
			width: auto;
		}
	}

	&:hover > div  {
		cursor: pointer;
	}

	&.add-child {
		&,
		&:hover {
			> div {
				background-color: rgba(0,161,240,0.1);
				border-color: rgba(0,161,240,0.25);
			}
		}
	}

	&.locked {
		a {
			color: #aaa;
		}

		&:hover > div  {
			background: #f2f2f2;
			cursor: default;
		}
	}

	.table-cell {
		> a {
			display: block;
			white-space: nowrap;
			padding: 0 15px;
		}

		&.table-cell-library-name {
			> a,
			> button {
				display: inline-block;
				padding-left: 0;
			}
		}
	}

	.admin-movable-item {
		display: table-cell;
	}

	&:hover,
	&.vb-droppable-hover  {
		> div {
			background: @white;
		}
	}
}

.vb-draggable-helper {
	width: 500px;
	z-index:100;
	display: block;
	.RoundedShape(4px);
	background: rgba(0,161,240,0.5);
	padding: 6px 6px 3px 32px;
	margin-left:32px;
	.BoxShadow(0 0 8px @blackFade50);

	.child-row > div  {
		background: @white;
	}

	.btn-add {
		background: @blackFade10;
		color: @blackFade50;
	}

	&.vb-draggable-hover .show-on-drag-target {
		display:block;
		position: absolute;
		left: 9px;
		top: 14px;
	}

	.child-row:focus {
		&,
		> div,
		a,
		.btn {
			.CursorGrabbing();
		}
	}
}

.vb-draggable-helper .spacer,
.show-on-drag-target{
 	display: none;
}
.vb-draggable-helper .hide-if-draggable-helper,
.vb-dragging .hide-while-dragging {
	display: none !important;
}

.child-row > div.drag-handle:hover,
.child-row > div.drag-handle:hover .btn,
.vb-draggable-helper .child-row:hover,
.vb-draggable-helper .child-row:hover > div,
.vb-draggable-helper .child-row:hover a,
.vb-draggable-helper .child-row:hover .btn {
	.CursorGrab();
}

/* .draggable-to-table fakes turning a draggable item list back into an admin table  */
.draggable-to-table {
	&.draggable-table {
		padding-bottom: 0;
	}

	.draggable-to-table .child-row > div {
		&.first-item,
		&.last-item {
			.RoundedShape(0);
			border-left: 0;
			border-right: 0;
		}
	}

	.child-container-padding {
		padding: 0;
	}
}

.item-count {
	text-align: center;

	a {
		padding-right: 30px;
		width: 140px;
	}
}

.child-row-child {
	.form-group {
		margin: 0 !important;
		padding: 0 !important;
		width: 100%;
	}

	h4 {
		color: @white;
		margin-top: 0;
	}
	.sub-dir & {
		margin-left: 30px;
	}
}

.container-head {
	> div {
		border-top: 1px solid @blackFade10;

		a {
			color: @color1LightText !important;
		}

		&:first-child a {
			margin-left: 5px;
		}

		&.table-cell-library-name  {
			padding-left: 5px;
		}

		&.last-item {
			.BorderTopRightRadius(0);
			.BorderBottomRightRadius(0);
			border-right: none;
			padding-right: 50px;
		}
	}

	> div,
	&:hover > div {
		background: #596065;
		color: fade(@white, 70%);
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.insertion-marker {
	display: none;
	position: absolute;
	right: 0;
	left: 25px;
	top: 24px;
	z-index: 1;

	.line {
		border-bottom: 4px solid #00a1f0;
	}

	&-icon {
		position: relative;
		top: 9px;
		background: #00a1f0;
		.RoundedShape(50%);
		height: 14px;
		width: 14px;
		color: @white;
	}

	.vb-droppable-hover & {
		display: block;
	}
}

/* child-form-2 TEST */
.child-form-2 {
	margin: 10px 30px 6px 30px;
	position: relative;
	background: @white;
	.RoundedShape(4px);
	padding: 10px 0;
	vertical-align: middle;
	font-weight: bold;
	.BoxShadow(0 0 8px @blackFade50);

	.form-group {
		margin: 0 !important;
		padding: 0 !important;
		width: 100%;
	}

	h4 {
		color: @white;
		margin-top: 0;
	}
}

.inline-create-form {
	&.child-form,
	&.child-form-2 {
		margin: 0;
		background: none;
		padding: 15px 15px 15px 30px;
		.BoxShadow(none);
		margin: 0;
		.RoundedShape(3px);
	}

	&.child-form {
		margin: 0 16px 1px 48px;
		padding: 6px;
		background: @whiteFade75;
	}
}

/* OLD PAGE FIXES */
.tree-container {
	display: table;
	width: 100%;
	margin-bottom: 4px;
	position: relative;

	a {
		text-decoration: none;
		display: block;
	}

	> div  {
		display: table-cell;
		width: 20%;
		width: 1px;
		margin: 5px 0;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		border-color: #ccc;
		padding: 6px;
		vertical-align: middle;
		font-weight: normal;
	}

	&:hover > div  {
		background: @white;
		cursor: pointer;
		cursor: hand;
	}

	.add-child {
		&,
		&:hover {
			> div {
				background-color: rgba(0,161,240,0.1);
				border-color: rgba(0,161,240,0.25);
			}
		}
	}
}

.root-insertion-marker{
	height: 10px;
	margin-top: -10px;

	.insertion-marker {
		left:15px;
	}
}

.child-container-padding {
	padding: 0 8px;
}

.child-container .vb-droppable,
.tree-item .vb-droppable {
	position: relative;
}

.child-sub-container {
	margin-left: 15px;
}

div.expand-icon button {
	margin: 0 5px;
}

.sub-dir .child-row > div.expand-icon button {
	margin-left: 30px;
}

.child-form {
	margin: 0 10px 5px 58px;
	position: relative;
	background: @white;
	.RoundedShape(4px);
	padding: 10px 0;
	vertical-align: middle;
	.BoxShadow(0 0 8px @blackFade50);
}

.collection-tree .child-container {
	padding: 0 15px;
}

.sample-cursor {
	position: absolute;
	top: 15px;
	left: 0;
	font-size: 18px;
}

.align-right-float-left {
	text-align: right;
	float: left;
	width: 50px;
}

.draggable-table {
	position: relative;
	background: rgba(89,96,101,0.3);
	padding: 0;
	padding-bottom: 15px;
}

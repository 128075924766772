@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

mobile-menu {
	align-self: center;
	order: 0;

	@media (max-width: @viewPortMedWidth) {
		flex-grow: 1;
	}

	a,
	a:hover,
	a:active,
	a:focus {
		color: inherit;
	}

	.btn {
		border-left: 1px solid fade(@black, 15%);
		.BoxShadow(0 1px 0 @blackFade50);
		color: @whiteFade50;
		height: 36px;
		padding: 6px 9px 6px 12px;

		.glyphicons {
			font-size: 18px;
			vertical-align: middle;
			top: 0;
		}
	}

	.btn-mobile {
		z-index: 10001;
		&-open {
			left: 105px;
		}
	}

	.mobile-menu {
		background-color: var(--theme-header) !important;

		ul {
			background: none !important;
		}

		.mobile-menu-list {

			.mobile-menu-list-item {
				margin-bottom: 10px;
				display: flex;
				flex-direction: column;
				color: var(--theme-header-txt);

				.help-menu-list, .media-box-list {
					list-style-type: none;
					a {
						padding-left: 25px !important;
					}
				}

				.default-help-link {
					padding-left: 15px;

					a {
						padding-left: 8px !important;
					}
				}

				.help-menu-container, .default-help-link {
					display: flex;
					flex-direction: row !important;
					justify-content: center;
					align-items: center;

					help-menu-mobile {
						flex-grow: 1;
					}

					.vb-icon {
						font-size: 1.2em;
					}
				}

				.vb-icon {
					position: relative;
					top: 2px;
					width: 24px;
					height: 24px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.label-text-spacing, a {
					display: flex;
					align-items: center;
					gap: 8px;
				}

				a, button {
					font-size: 1em;
					padding: 15px !important;
					text-align: left;
					background: none;
					border: none;
					margin: 0 !important;
					width: 100%;
					text-wrap: wrap;

					&.media-video-item {
						font-size: @type14;
						text-transform: none;
					}
				}


				&.active {

					&:hover {
						.clear-overlay;

						a {
							text-decoration: none;
						}
					}
				}

				&.isOpen {
					background-color: var(--theme-header-overlay);
				}
			}

			my-videos-menu-items {
				ul {
					li {
						margin-bottom: 10px;

						a, button {
							color: var(--theme-header-txt) !important;
						}

						&.active {
							.apply-overlay;
						}
						&:hover {
							.clear-overlay;
						}
					}
				}
			}
		}

		.media-video-item, .help-menu-item {
			font-size: @type16 !important;
			margin: 0;
			padding: 10px !important;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	.overlay(@overlayBGColor) {
		content: '';
		height: 100%;
		width: 10px;
		background-color: @overlayBGColor;
		.AbsoluteFill;
		z-index: 1;
	}

	.clear-overlay {
		&:after {
			content: none;
		}
	}

	.apply-overlay {
		position: relative;
		a {
			position: relative;
			text-decoration: underline;
			z-index: 2;
		}

		&:after {
			.overlay(@blackFade50)
		}
	}
}

.line-height-1 {
	line-height: 1;
}

user-nav-menu {
	white-space: nowrap;

	.btn-icon-left {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.vb-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5em;
		width: 1.5em;
		height: 1.5em;
	}
}
